import { Plugin } from '@nuxt/types';
import axios from 'axios';
import { v4 } from 'uuid';

type AnalyticsEventType = 'click' | 'pageView'; // <CHECK SPREADSHEET FOR MORE TYPES>

interface MzAnalyticsPlugin {
  $mzAnalyticsApi: ({
    event,
    name,
    productArea,
    module,
    entityType,
    entityId,
  }: {
    event: AnalyticsEventType;
    name?: string;
    productArea?: string;
    module?: string;
    entityType?: string;
    entityId?: number | string;
  }) => Promise<void>;
}

declare module '@nuxt/types' {
  interface Context extends MzAnalyticsPlugin {}
}

interface AnalyticsEvent {
  event: AnalyticsEventType;
  eventId: string; // <NEW GUID>
  sessionId: string; // <SESSION ID FROM COOKIE OR IN MEMORY GUID IF USER NOT LOGGED IN>
  time: number; // <TIMESTAMP IN EPOCH MS using Date.now()>
  context: {
    domain: 'teachstarter.com';
    referrer: string; // <FULL PATH - PREVIOUS PAGE>
    page: string; // <RELATIVE PATH - CURRENT PAGE>
    locale: string; // <LOCALE FROM COOKIE OR en-GB IF COOKIE IS EMPTY>
    url: string; // // <FULL PATH - CURRENT PAGE>
    product: 'tes.teachstarter';
    userAgent: string; // <USER AGENT FROM DEVICE> e.g.: Mozilla/5.0 (iPhone; CPU iPhone OS 9_1 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13B143
  };
  location: {
    city: string | null;
    county: string | null;
    country: string | null;
    salesforceLocationId: string | null;
  };
  user: {
    tuid: string; // <TUID FROM COOKIE>
    uid: string | number; // <UID FROM COOKIE OR NULL IF USER NOT LOGGED IN>
    organisationId: string;
    productOrganisationId: string;
  };
  data: {
    name: string; // <NAME FROM SPREADSHEET> List
    elementId: null;
    entityId: number | string | null; // <ID OF ARTICLE OR CATEGORY ETC. FROM API>
    entityType: string; //Resource
    productArea: string; // <PRODUCT AREA FROM SPREADSHEET>
    module: string; // Subgrouping of features
  };
}

const mzAnalyticsPlugin: Plugin = ({ $config, $axios, app, store }, inject) => {
  inject(
    'mzAnalyticsApi',
    async ({
      event,
      name,
      productArea,
      module,
      entityType,
      entityId,
    }: {
      event: AnalyticsEventType;
      name?: string;
      productArea?: string;
      module?: string;
      entityType?: string;
      entityId?: number | string;
    }) => {
      const referrer = store.state.previousUrl
        ? window.location.origin + store.state.previousUrl
        : document.referrer; // Previous page
      const user = app.$auth.user; // Access user directly from app.$auth
      const sessionId =
        document.cookie
          .split('; ')
          .find(row => row.startsWith('session_id='))
          ?.split('=')[1] || v4();

      const analyticsData: AnalyticsEvent = {
        event,
        eventId: v4(),
        sessionId,
        time: Date.now(),
        context: {
          domain: 'teachstarter.com',
          referrer,
          page: app.router?.currentRoute?.fullPath || '',
          locale: app.i18n.locale,
          url: window.location.href,
          product: 'tes.teachstarter',
          userAgent: navigator.userAgent,
        },
        location: {
          country: store.state.location.name,
          city: null,
          county: null,
          salesforceLocationId: null,
        },
        user: {
          tuid: user?.uuid || '',
          uid: user?.id || '',
          organisationId: '',
          productOrganisationId: '',
        },
        data: {
          name: name || 'null',
          elementId: null,
          entityId: entityId || null,
          entityType: entityType || '',
          productArea: productArea || '',
          module: module || '',
        },
      };

      try {
        const response = await $axios.post($config.analyticsApiUrl, analyticsData, {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Methods': '*',
            'Referrer-Policy': 'strict-origin-when-cross-origin',
          },
        });
      } catch (error) {
        console.error('Error sending event:', error);
      }
    }
  );
};

export default mzAnalyticsPlugin;
